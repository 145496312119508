<template>
  <div class="operations_archive">
    <div class="container">
      <el-card>
        <el-form ref="form" :model="queryInfo" label-width="120">
          <el-form-item label="运营状态：">
            <el-radio-group v-model="queryInfo.operationStatus" @change="changeSearchangeSearch">
              <el-radio-button label="">不限</el-radio-button>
              <el-radio-button
                :label="item.codeName"
                v-for="item in operateStateList"
                :key="item.codeId"
              >
                {{ item.codeName }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="所在行业：">
            <dic-tag-button
              ref="tagButton"
              :code.sync="industry"
              query-name="techIdName"
              name="所在行业"
              tag="行业"
              key="所在行业"
              @onChange="onChange"
            />
          </el-form-item>

          <el-form-item label="所在地区：">
            <city-select
              ref="child"
              :province="queryInfo.provinces"
              :city="queryInfo.city"
              :district="queryInfo.district"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
              @searchDistrict="searchDistrict"
            />
          </el-form-item>

          <!-- 底部 -->
          <el-form-item label=" " label-width="0" style="padding-top: 20px; margin-bottom: 0">
            <div class="newClue">
              <div>
                <el-button type="primary" @click="addOperationBtn">+运营档案</el-button>
                <el-button @click="distributionLists" v-if="getRoleId == 1">批量分配</el-button>
                <span class="span_checkbox">
                  <el-checkbox @change="changeCheckAll" v-model="checkAll">全选</el-checkbox>
                </span>
              </div>
              <!-- 右边搜索 -->
              <div class="search_right">
                <el-input
                  placeholder="请输入群聊名称/企业简称"
                  v-model="queryInfo.keyWord"
                  class="input-with-select"
                  style="width: 500px"
                >
                  <el-button slot="append" @click="changeSearchangeSearch">搜索</el-button>
                </el-input>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </el-card>

      <div class="total_time">
        <div class="total" style="padding-right: 30px">
          <span
            >当前条件下共查出<em>{{ total }}</em
            >条数据</span
          >
        </div>

        <div class="time">
          <div class="time_div">
            按最新运营时间
            <div class="main">
              <span
                class="arrUp"
                @click="clickUp1"
                :style="{ 'border-bottom-color': orderinfo.color1 }"
              ></span>
              <span
                class="arrDown"
                @click="clickDown2"
                :style="{ 'border-top-color': orderinfo.color2 }"
              ></span>
            </div>
          </div>
        </div>
      </div>

      <!-- 卡片列表 -->
      <div class="card_list" v-loading="loading">
        <div class="clue_card" v-for="(item, index) in datalist" :key="index">
          <div class="item_checkbox">
            <el-checkbox
              @change="latechange($event, item)"
              v-model="item.changeChecked"
            ></el-checkbox>
          </div>
          <div class="card_left">
            <!-- 企业图片区域 -->
            <div class="clue_img">
              <!-- <div class="img_left_top two">
                <span>{{ item.operationStatus }}</span>
              </div> -->
              <img v-if="item.companyLogo" :src="item.companyLogo" alt="" />
              <img v-else src="../../assets/image/Group 3237.png" alt="" />
            </div>
            <!-- 企业信息 -->
            <div class="card_info">
              <div class="card_title row">
                <div>
                  <el-popover placement="right" width="200" trigger="hover">
                    <div>{{ item.wechatGroupName }}</div>
                    <div slot="reference" class="clue_name">
                      {{ truncatedText(item.wechatGroupName) }}
                    </div>
                  </el-popover>
                </div>
              </div>
              <div class="bottom_row">
                <span style="margin-right: 20px">
                  <span style="margin-right: 5px">
                    <el-popover placement="right" width="300" trigger="hover">
                      <div>
                        <span>{{ item.companyName }}</span>
                        <span v-if="item.province"
                          >(
                          <span v-if="item.province">{{ item.province }}-{{ item.city }}</span>
                          <span v-if="item.district">-{{ item.district }}</span>
                          )</span
                        >
                      </div>
                      <div slot="reference">
                        {{ truncatedTextName(item.companyName) }}
                        <span v-if="item.province">({{ item.province }}-{{ item.city }})</span>
                      </div>
                    </el-popover>
                  </span>
                </span>
              </div>
              <div class="bottom_row_text">
                关注方向：
                <span v-if="item.focusDirection">
                  <el-popover placement="right" width="200" trigger="hover">
                    <span>{{ item.focusDirection }}</span>
                    <span slot="reference">
                      {{ truncatedText(item.focusDirection) }}
                    </span>
                  </el-popover>
                </span>
                <span v-else>-</span>
              </div>
              <div style="height: 25px">
                <el-tag
                  effect="plain"
                  v-for="(i1, i2) in item.operationStatus.split(',').slice(0, 2)"
                  :key="i2"
                  >{{ i1 }}</el-tag
                >
                <el-popover placement="right" width="200" trigger="hover">
                  <el-tag
                    size="mini"
                    v-for="(o, i3) in item.operationStatus.split(',')"
                    :key="i3"
                    effect="plain"
                    style="margin: 5px"
                    >{{ o }}</el-tag
                  >
                  <el-tag
                    slot="reference"
                    style="background: #fff"
                    v-if="item.operationStatus.split(',').length > 2"
                    >{{ item.operationStatus.split(',').length }}+</el-tag
                  >
                </el-popover>
                <!-- <el-row class="row" v-if="item.operationStatus">
                  <el-tag
                    effect="plain"
                    v-for="(i1, i2) in item.operationStatus.split(',').slice(0, 2)"
                    :key="i2"
                    >{{ i1 }}</el-tag
                  >
                  <div @mouseenter="showTags(index)" @mouseleave="hideTags" class="show_tags">
                    <el-tag style="cursor: pointer; background: #fff"
                      >{{ item.operationStatus.split(',').length }}+</el-tag
                    >
                    <div class="tags_box" v-show="isShowTags && tagIndex == index">
                      <el-tag
                        effect="plain"
                        v-for="(i1, i2) in item.operationStatus.split(',')"
                        :key="i2"
                        >{{ i1 }}</el-tag
                      >
                    </div>
                  </div>
                </el-row> -->
              </div>
              <!-- <div class="tag_item">
                <div
                  class="tag_item_div"
                  :class="countClass(tt)"
                  v-for="(tt, ii) in item.state"
                  :key="ii"
                >
                  {{ tt }}
                </div>
              </div> -->
            </div>
          </div>
          <div class="card_center_data">
            <div class="card_center_data_item">
              <img
                v-if="item.archivesStatus.split(',').includes('企业服务')"
                class="card_center_data_item_img"
                src="../../assets/image/Group 1000001609.png"
              />
              <img
                v-else
                class="card_center_data_item_img"
                src="../../assets/image/Group 1000001618.png"
              />
              <div class="card_center_data_item_text">企业</div>
            </div>
            <div class="card_center_data_item">
              <img
                v-if="item.archivesStatus.split(',').includes('小程序绑定')"
                class="card_center_data_item_img"
                src="../../assets/image/Group 1000001610.png"
              />
              <img
                v-else
                class="card_center_data_item_img"
                src="../../assets/image/Group 1000001614 (1).png"
              />
              <div class="card_center_data_item_text">小程序</div>
            </div>
            <div class="card_center_data_item">
              <img
                v-if="item.archivesStatus.split(',').includes('绑定建群')"
                class="card_center_data_item_img"
                src="../../assets/image/Group 1000001611.png"
              />
              <img
                v-else
                class="card_center_data_item_img"
                src="../../assets/image/Group 1000001616.png"
              />
              <div class="card_center_data_item_text">机器人</div>
            </div>
            <div class="card_center_data_item">
              <img
                v-if="item.archivesStatus.split(',').includes('需求明确')"
                class="card_center_data_item_img"
                src="../../assets/image/Group 1000001612.png"
              />
              <img
                v-else
                class="card_center_data_item_img"
                src="../../assets/image/Group 1000001615.png"
              />
              <div class="card_center_data_item_text">需求</div>
            </div>
            <div class="card_center_data_item">
              <img
                v-if="item.archivesStatus.split(',').includes('会员服务')"
                class="card_center_data_item_img"
                src="../../assets/image/Group 1000001613.png"
              />
              <img
                v-else
                class="card_center_data_item_img"
                src="../../assets/image/Group 1000001617.png"
              />
              <div class="card_center_data_item_text">会员</div>
            </div>
          </div>
          <div class="card_center_line"></div>
          <!-- 数据统计 -->
          <div class="count_list">
            <div class="col">
              <div class="row">{{ item.customerInquiriesNum || 0 }}</div>
              <div>
                <span>客户提问</span>
              </div>
            </div>
            <div class="col">
              <div class="row">{{ item.serviceNum || 0 }}</div>
              <div>
                <span>信息服务</span>
              </div>
            </div>
            <div class="col">
              <div class="row">{{ item.userReplyNum || 0 }}</div>
              <div>
                <span>问答服务</span>
              </div>
            </div>
            <!-- <div class="col" v-if="item.dayTim || item.hourTime">
              <div class="row">
                <div class="item">
                  <div>
                    <span v-if="item.dayTim"
                      ><em :class="item.expireStatus ? 'day_time' : ''">{{ item.dayTime }}</em
                      >天前</span
                    >
                    <span v-if="item.hourTim"
                      ><em>{{ item.hourTime }}</em
                      >小时前</span
                    >
                  </div>
                  <div class="right" v-if="item.expireStatus">
                    <i class="el-icon-warning-outline"></i>
                    已超期
                  </div>
                </div>
              </div>
              <div>
                <span>最近服务时间</span>
              </div>
            </div> -->

            <!-- <div class="col" @click="replyNumClick(item)">
              <div class="row color_row">{{ item.userReplyNum || 0 }}</div>
              <div>
                <span>客户回复</span>
              </div>
            </div> -->
            <div class="col" @click="responseNumClick(item)">
              <div class="row color_row">{{ item.responseNum || 0 }}</div>
              <div>
                <span>企业响应次数</span>
              </div>
            </div>
          </div>

          <!-- 右侧按钮 -->
          <div class="card_right">
            <div class="card_right_top">
              <div class="card_right_top_left">
                <span>最新运营时间：{{ item.operationTime }}</span>
                <i class="el-icon-edit" @click="operationClick(item)"></i>
              </div>
              <div class="card_right_top_right" v-if="item.expireStatus">
                <i class="el-icon-warning-outline"></i> 已超期
              </div>
            </div>
            <div class="btn_list row">
              <div class="list_distribution" v-if="!item.userName && getRoleId == 1">
                <img src="../../assets/image/Group 3803.png" />
                <span class="distribution" @click="distributionChange(item)">待分配</span>
              </div>
              <div class="list_distribution" v-if="getRoleId == 1 && item.userName">
                <img
                  v-if="item.userName"
                  style="width: 28px; height: 28px"
                  src="../../assets/image/男生头像 1.png"
                />
                <span class="distribution_name" @click="distributionChange(item)">{{
                  item.userName
                }}</span>
              </div>
              <div class="btn_list_button">
                <el-button type="primary" @click="contentClick(item)" class="btn_list_content"
                  ><i class="el-icon-bank-card"></i>内容管理<em v-if="item.contentNum"
                    >({{ item.contentNum }})</em
                  ></el-button
                >
                <!-- <el-button type="text" @click="search()">
                <i class="el-icon-refresh-right"></i>手动更新
              </el-button> -->
                <span @click="editArchiveBtn(item)" class="click" style="color: #4e93fb">编辑</span>
                <span v-if="item.notFreeze" class="frozen" @click="delcompany(item)">冻结</span>
                <span v-else style="color: #4e93fb" class="click" @click="delcompany(item)"
                  >激活</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 分页区域 -->
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[4, 10, 30]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 分配顾问弹窗 -->
    <el-dialog :visible.sync="distributionDialogVisible" width="70%" center>
      <span slot="title" class="custom-dialog-title">
        <div class="left_text">分配顾问</div>
      </span>

      <div class="my-centered-content">
        <div style="border-bottom: 2px solid #f5f6f8ce; width: 95%">
          <el-form :inline="true" :model="queryInfoPage" class="demo-form-inline">
            <el-form-item label="顾问姓名">
              <el-input
                style="width: 350px"
                v-model="queryInfoPage.useName"
                placeholder="请输入"
                clearable
                @clear="distributionSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" style="margin-left: 40px">
              <el-input
                style="width: 350px"
                v-model="queryInfoPage.phone"
                placeholder="请输入"
                clearable
                @clear="distributionSearch()"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="distributionList">搜索</el-button>
              <el-button @click="distributionListReset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div style="width: 95%">
          <el-table
            ref="multipleTable"
            :data="distributionTableData"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            @select="selectData"
          >
            <!-- <el-table-column>
              <template v-slot="{ row }">
                <el-radio v-model="row.id"></el-radio>
              </template>
            </el-table-column> -->
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="顾问头像">
              <template>
                <img style="width: 50px; height: 50px" src="../../assets/image/男生头像 1.png" />
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="姓名"> </el-table-column>
            <el-table-column prop="mobile" label="手机号"> </el-table-column>
            <el-table-column prop="num" label="关联企业"> </el-table-column>
            <el-table-column label="最近登录时间">
              <template v-slot="{ row }">
                <div>{{ row.loginTime | date }}</div>
                <div>{{ row.loginTime | time }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="new_page_son">
          <el-pagination
            style="margin: 10px auto"
            :current-page="queryInfoPage.pageNum"
            :page-sizes="[4, 10, 30]"
            :page-size="queryInfoPage.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalPage"
            @size-change="handleSizeChangePage"
            @current-change="handleCurrentChangePage"
          />
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="distributionDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAddAdviser">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑响应次数弹窗 -->
    <el-dialog :visible.sync="responseTimesDialogVisible" width="30%" center>
      <span slot="title" class="custom_dialog_title">
        <div class="left_text">编辑响应次数</div>
      </span>

      <div class="my-centered-content">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="响应次数">
            <el-input
              v-model="updataInfo.responseNum"
              style="width: 350px"
              placeholder="请输入"
              @input="validateInput"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelResponse">取 消</el-button>
        <el-button type="primary" @click="saveResponse">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑最新运营时间弹窗 -->
    <el-dialog :visible.sync="operationTimeDialogVisible" width="30%" center>
      <span slot="title" class="custom_dialog_title">
        <div class="left_text">编辑最新运营时间</div>
      </span>

      <div class="my-centered-content">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="最新运营时间">
            <el-date-picker
              v-model="updataInfo.operationTime"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelOperation">取 消</el-button>
        <el-button type="primary" @click="saveOperation">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CitySelect from '@/components/CitySelect.vue'
import DicTagButton from '@/components/DicTagButton.vue'
import {
  selectArchivesPage,
  deleteArchive,
  selectArchivesUserPage,
  insertConsultant,
  updateArchiveResponseNumAndOperationTime
} from '@/api/archives.js'
import { getCode } from '@/api/demand.js'
const defaultQueryInfo = Object.freeze({
  city: '', //市
  keyWord: '', //模糊查询关键字
  labels: [], //国名经济标签组
  operationStatus: '', //运营状态
  orderByParam: 'DESC', //排序字段+ DESC或ASC
  orders: '1', //排序 默认传1 2->按更新时间升序;3->按更新时间降序;4->按服务时间升序;5->按服务时间降序
  pageNum: 1,
  pageSize: 10,
  province: '', //省
  district: ''
})
const defaultQueryInfoPage = Object.freeze({
  id: '',
  orderByParam: '',
  orders: 0,
  pageNum: 1,
  pageSize: 10,
  phone: '',
  useName: ''
})
const defaultOrder = Object.freeze({
  color1: '',
  color2: '#C6C6C6',
  color3: '',
  color4: '#C6C6C6',
  color5: '',
  color6: '#C6C6C6',
  color7: '',
  color8: '#C6C6C6',
  color9: '',
  color10: '#C6C6C6',
  color11: '',
  color12: '#C6C6C6'
})
const defaultUpdateInfo = Object.freeze({
  archiveUserList: [],
  archivesStatus: '',
  archivesStatusList: [],
  companyFullName: '',
  companyGrade: '',
  companyId: '',
  companyLogo: '',
  companyName: '',
  id: 0,
  operationStatus: '',
  operationStatusList: [],
  operationTime: '',
  phone: '',
  position: '',
  realName: '',
  responseNum: '',
  userId: ''
})
export default {
  components: { CitySelect, DicTagButton },
  name: 'operationsArchive',
  computed: {
    countClass() {
      let className
      return (val) => {
        switch (val) {
          case '绑定建群':
            className = 'class_name1'
            break
          case '企业调研':
            className = 'class_name2'
            break
          case '小程序绑定':
            className = 'class_name3'
            break
          case '是否会员':
            className = 'class_name4'
            break
          case '是否专家服务':
            className = 'class_name5'
            break
          case '是否有明确需求':
            className = 'class_name6'
            break
          case '客户冻结':
            className = 'class_name7'
            break
          case '正常运营':
            className = 'class_name8'
            break
          case '会员到期':
            className = 'class_name9'
            break
          case '甄选服务':
            className = 'class_name10'
            break

          default:
            className = ''
            break
        }

        return className
      }
    },
    getRoleId() {
      return sessionStorage.getItem('roleId')
    }
  },
  data() {
    return {
      tagIndex: '',
      //控制是否显示更多标签
      isShowTags: false,
      checkAll: false,
      //排序字段
      orderinfo: { ...defaultOrder },
      queryInfoPage: { ...defaultQueryInfoPage },
      updataInfo: { ...defaultUpdateInfo },
      industry: '', //所在行业
      operateStateList: [], //运营状态
      //查询参数
      queryInfo: { ...defaultQueryInfo },
      datalist: [],
      total: 0,
      totalPage: 0,
      loading: false,
      ids: [],
      distributionDialogVisible: false, // 分配顾问弹窗
      responseTimesDialogVisible: false, // 响应次数弹窗
      operationTimeDialogVisible: false, // 编辑最新运营时间弹窗
      distributionTableData: [], // 分配列表
      addAdviser: {
        ids: [],
        sysUserId: ''
      }
    }
  },
  created() {
    //获取运营状态
    this.getCodeList('023')
    // this.search()
    if (this.$route.query.changeNum) {
      this.queryInfo.pageNum = Number(localStorage.getItem('paginationCurrentPage'))
      this.search()
    } else {
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  methods: {
    // 点击内容管理
    contentClick(row) {
      this.$router.push({
        path: '/operate/content',
        query: {
          archiveId: row.id,
          companyId: row.companyId
        }
      })
    },
    // 限制字数
    truncatedText(val) {
      if (!val) return
      if (val.length > 6) {
        return val.slice(0, 6) + '...'
      }
      return val
    },
    // 限制简称字数
    truncatedTextName(val) {
      if (!val) return
      if (val.length > 6) {
        return val.slice(0, 6)
      }
      return val
    },
    // 编辑
    editArchiveBtn(row) {
      this.$router.push({
        path: '/operate/editor',
        query: {
          archiveId: row.id,
          pageNum: this.queryInfo.pageNum
        }
      })
    },
    // 激活  冻结解冻
    delcompany(row) {
      const query = {
        id: row.id,
        freezeValue: false
      }
      if (row.notFreeze) {
        query.freezeValue = false
      } else {
        query.freezeValue = true
      }
      this.$alert(`您是否确认${row.notFreeze ? '冻结' : '激活'}？`)
        .then(function () {
          return deleteArchive(query)
        })
        .then(() => {
          this.search()
          this.$message.success(`${row.notFreeze ? '冻结' : '激活'}成功`)
        })
        .catch(() => {})
    },
    //服务次数点击
    // serviceBtn(row) {},
    //客户回复点击
    replyNumClick(row) {
      this.$router.push({
        path: '/operate/response',
        query: {
          archiveId: row.id,
          companyId: row.companyId
        }
      })
    },
    // 企业响应次数点击
    responseNumClick(row) {
      this.responseTimesDialogVisible = true
      this.updataInfo.responseNum = row.responseNum
      this.updataInfo.id = row.id
    },

    // 最新运营时间点击
    operationClick(row) {
      this.operationTimeDialogVisible = true
      this.updataInfo.operationTime = row.operationTime
      this.updataInfo.id = row.id
    },

    showTags(i) {
      this.tagIndex = i
      this.isShowTags = true
    },
    hideTags() {
      this.isShowTags = false
    },
    //排序按创建时间
    clickUp1() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '2'
      this.search()
      this.orderinfo.color1 = '#0099ff'
      this.orderinfo.color2 = ''
    },
    clickDown2() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '3'
      this.search()
      this.orderinfo.color1 = ''
      this.orderinfo.color2 = '#0099ff'
    },
    //获取运营状态
    async getCodeList(val) {
      const { data: res } = await getCode({ codeType: val })
      if (res.resultCode == 200) {
        this.operateStateList = res.data
      }
    },
    // 查询分配列表
    async distributionSearch() {
      const { data: res } = await selectArchivesUserPage(this.queryInfoPage)
      if (res.resultCode === 200) {
        this.distributionTableData = res.data.list
        this.totalPage = res.data.total
      } else {
        this.$message.error('分配失败！')
      }
    },
    // 保存分配顾问信息
    async saveAdviser() {
      const { data: res } = await insertConsultant(this.addAdviser)
      if (res.resultCode === 200) {
        this.$message.success('分配成功！')
        this.datalist.forEach((item) => {
          this.$set(item, 'changeChecked', false)
        })
        this.checkAll = false
        this.distributionDialogVisible = false
        this.search()
      } else {
        this.$message.error('分配失败！')
      }
    },
    // 修改响应次数和时间
    async updateResponseNumAndOperationTime() {
      const { data: res } = await updateArchiveResponseNumAndOperationTime(this.updataInfo)
      if (res.resultCode === 200) {
        this.$message.success('修改成功！')
        this.search()
      } else {
        this.$message.error('修改失败！')
      }
    },

    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      this.queryInfo.pageNum = 1
      this.queryInfo.labels = checkboxVal
      this.search()
    },
    //点击新增运营档案
    addOperationBtn() {
      this.$router.push({
        path: '/operate/add'
      })
    },

    // 分配顾问
    distributionChange(val) {
      if (this.getRoleId == 1) {
        this.distributionDialogVisible = true
        this.queryInfoPage.id = val.id
        this.addAdviser.ids.push(val.id)
        this.distributionSearch()
      } else {
        this.$message.warning('你没有权限哦！')
      }
    },
    // 批量分配
    distributionLists() {
      if (this.addAdviser.ids.length == 0) {
        this.$message.warning('请选择要分配的顾问！')
      } else {
        this.distributionDialogVisible = true
        this.distributionSearch()
      }
    },

    // 模糊查询列表数据
    distributionList() {
      this.distributionSearch()
    },
    // 点击重置
    distributionListReset() {
      this.queryInfoPage.useName = ''
      this.queryInfoPage.phone = ''
      this.distributionSearch()
    },

    // 限制用户输入
    validateInput(value) {
      this.updataInfo.responseNum = /^\d*$/.test(value) ? value : value.replace(/[^\d]/g, '')
    },
    // 点击保存响应次数
    saveResponse() {
      this.responseTimesDialogVisible = false
      this.updateResponseNumAndOperationTime()
    },
    // 点击取消
    cancelResponse() {
      this.responseTimesDialogVisible = false
    },

    // 点击保存最新运营时间
    saveOperation() {
      this.operationTimeDialogVisible = false
      this.updateResponseNumAndOperationTime()
    },
    // 点击取消
    cancelOperation() {
      this.operationTimeDialogVisible = false
    },

    // 选择某一条
    handleSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.multipleTable.toggleRowSelection(val[0])
      }
    },
    selectData(val, item) {
      this.addAdviser.sysUserId = item.id
    },
    // 多选框选中数据
    latechange(bol, row) {
      if (bol) {
        this.$set(row, 'changeChecked', true)
      } else {
        this.$set(row, 'changeChecked', false)
      }
      const index = this.addAdviser.ids.indexOf(row.id)
      if (index == -1) {
        this.addAdviser.ids.push(row.id)
      } else {
        this.addAdviser.ids.splice(index, 1)
      }
    },
    // 全选
    changeCheckAll(val) {
      if (val) {
        this.datalist.forEach((item) => {
          this.$set(item, 'changeChecked', true)
        })
        this.addAdviser.ids = []
        this.datalist.forEach((item) => {
          this.addAdviser.ids.push(item.id)
        })
      } else {
        this.datalist.forEach((item) => {
          this.$set(item, 'changeChecked', false)
        })
        this.addAdviser.ids = []
      }
      console.log(this.addAdviser.ids)
    },

    // 保存
    saveAddAdviser() {
      this.saveAdviser()
    },
    //查询一栏
    async search() {
      this.loading = true
      const data = {
        ...this.queryInfo,
        roleId: Number(this.getRoleId)
      }
      const { data: res } = await selectArchivesPage(data)
      if (res.resultCode === 200) {
        this.datalist = res.data.list
        this.datalist.forEach((item) => {
          this.$set(item, 'changeChecked', false)
        })
        this.total = res.data.total
      } else {
        this.$message.error('获取线索信息失败！')
      }
      this.loading = false
    },
    //返回省
    searchProvince(val) {
      this.queryInfo.pageNum = 1
      this.queryInfo.province = val
      this.search()
    },
    //返回城市
    searchCity(val) {
      this.queryInfo.city = val
      this.search()
    },
    //返回区/县
    searchDistrict(val) {
      this.queryInfo.district = val
      this.search()
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      localStorage.setItem('paginationCurrentPage', newCurrent)
      this.search()
    },
    //监听分配pagesize变化
    handleSizeChangePage(newSize) {
      this.queryInfoPage.pageSize = newSize
      this.distributionSearch()
    },
    //监听分配页码变化
    handleCurrentChangePage(newCurrent) {
      this.queryInfoPage.pageNum = newCurrent
      this.distributionSearch()
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.operations_archive {
  width: 100%;
  overflow: auto;

  .container {
    height: calc(100vh - 150px);
    overflow: auto;
  }
  .total_time {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 10px 0;
    justify-content: flex-start;
    align-items: center;

    .main {
      display: flex;
      flex-direction: column;
    }

    .arrUp {
      width: 0;
      height: 0;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 8px solid #c6c6c6;
      cursor: pointer;
    }

    .arrDown {
      width: 0;
      height: 0;
      margin-top: 2px;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-top: 8px solid #c6c6c6;
      cursor: pointer;
    }

    .total {
      // width: 60%;
      color: #393939;
      font-size: 16px;
      font-weight: 400;

      em {
        color: #4e93fb;
        font-style: normal;
      }
    }

    .time {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .time_div {
        color: #595959;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        margin-right: 30px;

        div {
          margin-left: 4px;
        }
      }
    }
  }

  ::v-deep .el-radio-button__inner {
    border: none;
    margin-right: 12px;
    border-radius: 4px;
  }

  .newClue {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    .span_checkbox {
      margin-left: 15px;
    }
  }

  .search_right {
    width: 500px;
    margin-right: 40px;
    ::v-deep .el-input-group__append {
      border-right: 0;
    }
    ::v-deep .el-button {
      border: #448aff 1px solid;
      color: #fff;
      background-color: #448aff;
    }
  }
}

.card_list {
  width: 100%;
  min-height: 44vh;
}
.clue_card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 20%;
  margin-bottom: 10px;
  background: #ffffff;
  padding: 20px 20px;
  box-sizing: border-box;
  align-items: center;
}

.card_left {
  display: flex;
  // width: 50%;
  width: 320px;
  height: 100%;
  .clue_img {
    flex-shrink: 0;
    position: relative;
    width: 88px;
    height: 88px;
    margin-right: 10px;
    border-radius: 6px;
    img {
      width: 88px;
      height: 88px;
      border-radius: 6px;
    }
  }
  .img_left_top {
    position: absolute;
    width: 93px;
    height: 34px;
    color: #fff;
    text-align: center;
    line-height: 34px;
    border-radius: 10px 10px 10px 0;
    top: 0;
    left: 0;
    transform: translate(-10px, -10px);
  }
  .one {
    background: #ff7d18;
  }
  .two {
    background: #599afb;
  }
  .three {
    background: #999999;
  }
  .four {
    background: #2cca4f;
  }
  .card_info {
    flex: 1;
    .el-row {
      display: flex;
      flex-flow: row nowrap;
    }
    .show_tags {
      position: relative;
      width: 50px;
      .tags_box {
        display: flex;
        flex-flow: row wrap;
        justify-content: left;
        width: 400px;
        padding-left: 10px;
        background-color: rgba(241, 241, 241, 0.6);
        box-shadow: 0 2px 4px rgba(241, 241, 241, 0.25);
        position: absolute;
        left: 100%;
        top: 100%;
        z-index: 999 !important;
        .el-tag {
          height: 18px;
          margin: 10px 10px 0 0;
          padding: 0 5px;
          line-height: 16px;
          font-size: 12px;
        }
      }
    }
    .row {
      margin-bottom: 15px;
    }
    .clue_name {
      font-size: 18px;
      font-weight: bolder;
      overflow: hidden;
      min-width: 40px;
      max-width: 200px;
    }
    .el-tag {
      height: 18px;
      margin-right: 10px;
      padding: 0 5px;
      line-height: 16px;
      font-size: 12px;
    }
    .card_title {
      display: flex;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }

      .type_culb {
        box-sizing: border-box;
        padding: 2px 6px;
        border-radius: 4px;
        background-color: #4e93fb;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
    .corp_name {
      font-size: 16px;
      line-height: 20px;
      padding-right: 10px;
      border-right: #e8e8e8 1px solid;
    }
    .clue_stage {
      color: #4e93fb;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    .bottom_row {
      display: flex;
      font-size: 14px;
      line-height: 8px;
      margin-bottom: 6px;
    }
    .bottom_row_text {
      color: #4e93fb;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 6px;
    }
    .clue_num {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #6e6e6e;
      font-size: 14px;
      margin-right: 10px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }

  .souce {
    width: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    .souce_item {
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      margin-left: 20px;

      span {
        font-size: 24px;
        color: #4e93fb;
        font-weight: bold;
        line-height: 26px;
      }
    }
  }
}

.count_list {
  display: flex;
  padding: 10px;
  width: 450px;
  .row {
    color: #333333;
    font-size: 20px;
    margin-bottom: 10px;
    font-size: 20px;

    .item {
      display: flex;

      em {
        font-style: normal;
      }

      .right {
        font-size: 12px;
        color: #fd702b;
        font-weight: 400;

        i {
          font-size: 12px;
          color: #fd702b;
          font-weight: 400;
        }
      }
    }
  }
  .col {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 20px;
  }

  .color_row {
    color: #4e93fb;
  }
  :hover span {
    color: #4e93fb;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
  }
}

.card_right {
  display: flex;
  flex-direction: column;
  width: 367px;
  .card_right_top {
    width: 100%;
    margin-bottom: 36px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    // justify-content: space-between;
    // align-items: center;

    .card_right_top_left {
      // width: 100%;
      overflow: hidden;

      i {
        color: #448aff;
        font-size: 16px;
        font-weight: 400;
      }

      span {
        color: #969495;
        font-size: 14px;
        font-weight: 400;
        margin-left: 4px;
      }
    }

    .card_right_top_right {
      font-size: 12px;
      color: #fd702b;
      font-weight: 400;

      i {
        font-size: 12px;
        color: #fd702b;
        font-weight: 400;
      }
    }
  }
  .frozen {
    color: #fd5469;
    cursor: pointer;
  }
  .row {
    margin-bottom: 10px;
  }
  .btn_list {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    em {
      font-style: normal;
    }
    ::v-deep .el-button--primary {
      font-size: 16px;
    }
    ::v-deep .el-button--text {
      font-size: 16px;
    }
    span {
      margin-left: 12px;
    }
    .click {
      cursor: pointer;
    }
    .list_distribution {
      display: flex;
      align-items: center;
      width: 124px;
    }
    .distribution {
      margin-right: 20px;
      cursor: pointer;
      color: #fba24e;
      border-bottom: 1px solid #fba24e;
    }
    .distribution_name {
      margin-right: 20px;
      cursor: pointer;
      color: rgba(33, 122, 247, 1);
      border-bottom: 1px solid #448aff;
    }
  }
  .operate {
    display: flex;
    justify-content: right;
    font-size: 10px;
    color: #999;
    margin-bottom: 10px;
  }
}
.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
.new_page_son {
  width: 94%;
  display: flex;
  align-items: center;
}

.tag_item {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;

  .tag_item_div {
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
    padding: 3px 8px;
    color: #999999;
    background-color: rgba(153, 153, 153, 0.1);
    margin-right: 8px;
  }

  .class_name1 {
    color: rgba(33, 122, 247, 1);
    background-color: rgba(224, 237, 255, 1);
  }
  .class_name2 {
    color: rgba(238, 113, 241, 1);
    background-color: rgba(238, 113, 241, 0.1);
  }
  .class_name3 {
    color: rgba(9, 215, 228, 1);
    background-color: rgba(9, 215, 228, 0.1);
  }
  .class_name4 {
    color: rgba(231, 158, 17, 1);
    background-color: rgba(231, 158, 17, 0.1);
  }
  .class_name5 {
    color: rgba(231, 158, 17, 1);
    background-color: rgba(231, 158, 17, 0.1);
  }
  .class_name6 {
    color: rgba(231, 158, 17, 1);
    background-color: rgba(231, 158, 17, 0.1);
  }
  .class_name7 {
    color: rgba(153, 153, 153, 1);
    background-color: rgba(153, 153, 153, 0.1);
  }
  .class_name8 {
    color: rgba(33, 122, 247, 1);
    background-color: rgba(224, 237, 255, 1);
  }
  .class_name9 {
    color: rgba(153, 153, 153, 1);
    background-color: rgba(153, 153, 153, 0.1);
  }
  .class_name10 {
    color: rgba(33, 122, 247, 1);
    background-color: rgba(224, 237, 255, 1);
  }
}
.custom-dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 550;
  padding: 0 20px 20px 20px;
  border-bottom: 2px solid #f5f6f8ce;
}
.custom_dialog_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 550;
}
.left_text {
  font-weight: 600;
}
.my-centered-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* 根据需要设置其他样式 */
}
::v-deep .has-gutter .el-table-column--selection .cell {
  display: none;
}
.el-icon-edit {
  cursor: pointer;
  margin-left: 5px;
}
.card_center_data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  .card_center_data_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    .card_center_data_item_img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
    .card_center_data_item_text {
      font-size: 13px;
    }
  }
}
.card_center_line {
  height: 60px;
  border: #dbdddb7a 1px solid;
  margin-left: 30px;
}
.item_checkbox {
  line-height: 36px;
  margin: 0;
}
.btn_list_content {
  width: 150px;
}
.btn_list_button {
  width: 238px;
}
</style>
